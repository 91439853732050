<template>
  <div class="iotProductForm">
    <form-panel ref="formPanel" v-bind="submitConfig">
      <div class="iotDeviceForm-body">
        <div class="iotDeviceForm-t">
          <v-steps :stepData="stepData" :active="active"></v-steps>
        </div>
        <div class="iotDeviceForm-b">
          <div class="iotDeviceForm-b-l" v-if="active == 0">
            <!--配置事件  -->
            <el-form-item label="配置事件" :label-width="labelWidht">
              <v-radio
                :radioObj="eventType"
                v-model="eventConfig.ruleType"
              ></v-radio>
            </el-form-item>
            <div class="ruleBox" v-if="eventConfig.ruleType == 0">
              <!-- 选择园区 -->
              <el-form-item label="选择园区" :label-width="labelWidht">
                <community-select
                  @change="eventCommunityChange"
                  v-model="eventConfig.spaceId"
                  specifyCollectionTypes=""
                  spaceTypes="10,30"
                />
              </el-form-item>
              <!-- 选择产品 -->
              <div class="ruleBox-top">
                <div class="ruleBox-top-l">
                  <el-form-item label="选择产品" :label-width="labelWidht">
                    <v-select
                      placeholder="请选择产品"
                      clearable
                      :options="productList"
                      v-model="eventConfig.productIds"
                    />
                  </el-form-item>
                </div>
              </div>
              <!--事件绑定 -->
              <div class="ruleBox-bottom">
                <el-form-item label="事件绑定" :label-width="labelWidht">
                  <v-select
                    placeholder="请选择事件绑定"
                    clearable
                    :options="eventContentLIst"
                    v-model="eventConfig.issueName"
                    @change="handelEventBinding"
                  />
                </el-form-item>
              </div>
              <div class="ruleBox-bottom">
                <div class="item-lable">事件告警自动恢复期限:</div>
                <div class="item">
                  <v-input
                    width="240"
                    placeholder="请输入事件告警自动恢复期限"
                    clearable
                    v-model="eventConfig.issueConfig.issue_recover_time"
                  />
                </div>
                <div class="item">秒</div>
              </div>
            </div>
          </div>
          <div class="iotDeviceForm-b-l" v-if="active == 1">
            <el-form-item label="配置规则">
              <v-radio
                :radioObj="configRuleOPt"
                v-model="iotRule.rule_type"
              ></v-radio>
            </el-form-item>
            <div v-if="iotRule.rule_type == 0">
              <el-form-item label="选择园区">
                <community-select
                  @change="communityChange"
                  v-model="iotRule.spaceId"
                  specifyCollectionTypes=""
                  spaceTypes="10,30"
                />
              </el-form-item>
              <el-form-item label="规则名称">
                <v-input
                  placeholder="请输入规则名称"
                  v-model="iotRule.ruleName"
                />
              </el-form-item>
              <el-form-item label="规则类型">
                <v-radio
                  :radioObj="ruleType"
                  v-model="iotRule.ruleType"
                ></v-radio>
              </el-form-item>
              <el-form-item v-if="iotRule.ruleType == 1" label="校验方式">
                <v-radio
                  :radioObj="checkMode"
                  v-model="iotRule.ruleComplexConfig.checkType"
                ></v-radio>
              </el-form-item>
              <!-- 规则配置列表-单一规则 -->
              <div class="ruleBox" v-if="iotRule.ruleType == 0">
                <div class="ruleBox-top">
                  <div class="ruleBox-top-l">
                    <el-form-item label="选择产品">
                      <v-select
                        placeholder="请选择产品"
                        clearable
                        :options="productList"
                        v-model="iotRule.ruleSimpleConfig.productId"
                        @change="productChange"
                      />
                    </el-form-item>
                  </div>
                </div>
                <div class="ruleBox-bottom">
                  <div class="item">校验规则:</div>
                  <div class="item">
                    <v-select
                      clearable
                      :options="productOptionList"
                      v-model="iotRule.ruleSimpleConfig.configs[0].value1"
                      placeholder="请选择商品属性"
                      @change="productOptionChange()"
                    />
                  </div>
                  <div class="item">
                    <v-select
                      clearable
                      :options="markSelect"
                      v-model="iotRule.ruleSimpleConfig.configs[0].value2"
                      placeholder="请选择"
                      @change="markSelectChange()"
                    />
                  </div>
                  <div class="item">
                    <v-input
                      v-model="iotRule.ruleSimpleConfig.configs[0].value3"
                      placeholder="数字或字符"
                      @input="selectInput()"
                    ></v-input>
                  </div>
                  <div class="item">或</div>
                  <div class="item">
                    <v-input
                      clearable
                      type="textarea"
                      :width="250"
                      :maxlength="10000"
                      placeholder="请输入表达式"
                      v-model="iotRule.ruleSimpleConfig.configs[0].operates"
                    ></v-input>
                  </div>
                  <div class="item">检验有效期限:</div>
                  <div class="item">
                    <v-input
                      v-model="iotRule.ruleSimpleConfig.validTime"
                      placeholder="请输入检验有效期限"
                    ></v-input>
                  </div>
                  <div class="item">秒</div>
                </div>
              </div>
              <!-- 规则配置列表-组合规则 -->
              <div class="ruleBox" v-if="iotRule.ruleType == 1">
                <div
                  class="box"
                  v-for="(item, index) in iotRule.ruleComplexConfig.configs"
                  :key="index"
                >
                  <div class="ruleBox-top">
                    <div class="ruleBox-top-l">
                      <el-form-item label="选择产品">
                        <v-select
                          placeholder="请选择产品"
                          clearable
                          :options="productList"
                          v-model="item.productId"
                          @change="productChange"
                        />
                      </el-form-item>
                      <el-form-item
                        v-if="
                          iotRule.ruleComplexConfig.checkType == 0 &&
                          iotRule.ruleType == 1
                        "
                        label="排序"
                      >
                        <v-input
                          v-model="item.sort"
                          placeholder="请输入排序"
                        ></v-input>
                      </el-form-item>
                    </div>
                    <!-- 操作按钮 -->
                    <div class="ruleBox-top-r" v-if="iotRule.ruleType == 1">
                      <v-button
                        v-if="iotRule.ruleComplexConfig.configs.length > 1"
                        text="删除"
                        type="danger"
                        @click="removeItem(item, index)"
                        class="removeBtn"
                      ></v-button>
                      <v-button
                        v-if="
                          index + 1 == iotRule.ruleComplexConfig.configs.length
                        "
                        @click="addItem(item, index)"
                        text="新增"
                      ></v-button>
                    </div>
                  </div>
                  <!-- 校验规则 -->
                  <div class="ruleBox-bottom">
                    <div class="item">校验规则:</div>
                    <div class="item">
                      <v-select
                        clearable
                        :options="productOptionList"
                        v-model="item.configs[0].value1"
                        placeholder="请选择商品属性"
                        @change="productOptionChange(item, index)"
                      />
                    </div>
                    <div class="item">
                      <v-select
                        clearable
                        :options="markSelect"
                        v-model="item.configs[0].value2"
                        placeholder="请选择"
                        @change="markSelectChange(item, index)"
                      />
                    </div>
                    <div class="item">
                      <v-input
                        v-model="item.configs[0].value3"
                        placeholder="数字或字符"
                        @input="selectInput(item, index)"
                      ></v-input>
                    </div>
                    <div class="item">或</div>
                    <div class="item">
                      <v-input
                        clearable
                        type="textarea"
                        :width="250"
                        :maxlength="10000"
                        placeholder="请输入表达式"
                        v-model="item.configs[0].operates"
                      ></v-input>
                    </div>
                    <div class="item">检验有效期限:</div>
                    <div class="item">
                      <v-input
                        v-model="item.validTime"
                        placeholder="请输入检验有效期限"
                      ></v-input>
                    </div>
                    <div class="item">秒</div>
                  </div>
                </div>
              </div>
              <!-- 规则关系 -->
              <div
                class="ruleRelationshipBox"
                v-if="
                  iotRule.ruleType == 1 &&
                  iotRule.ruleComplexConfig.checkType == 1
                "
              >
                <div class="item">规则关系:</div>
                <div class="item">
                  <v-select
                    :options="productList"
                    v-model="iotRule.ruleComplexConfig.ruleRelatedLeftProducts"
                    placeholder="请选择产品"
                    :multiple="true"
                  />
                </div>
                <div class="item">
                  <v-select
                    :options="ruleRelationship"
                    v-model="iotRule.ruleComplexConfig.ruleRelatedOper"
                    placeholder="请选择"
                  />
                </div>
                <div class="item">
                  <v-select
                    :options="productList"
                    v-model="iotRule.ruleComplexConfig.ruleRelatedRightProducts"
                    placeholder="请选择产品"
                    :multiple="true"
                  />
                </div>
                <div class="item">
                  此处产品下拉框都支持多选，多选的产品表示条件同时满足
                </div>
              </div>
            </div>
          </div>
          <!-- 告警配置 -->
          <div class="iotDeviceForm-b-l" v-if="active == 2">
            <el-form-item label="告警类型">
              <v-select
                v-model="notifyConfig.eventType"
                clearable
                :options="notifyType"
                placeholder="请选择告警类型"
              />
            </el-form-item>
            <el-form-item label="告警名称">
              <v-input
                v-model="notifyConfig.eventName"
                placeholder="请输入告警名称"
              />
            </el-form-item>
            <el-form-item label="告警内容">
              <v-input
                v-bind="{ width: 500 }"
                v-model="notifyConfig.eventContent"
                placeholder="请输入告警内容"
              />
            </el-form-item>
            <el-form-item label="通知方式">
              <v-select
                v-model="notifyConfig.type"
                clearable
                :options="notifyMode"
                placeholder="请选择通知方式"
              />
            </el-form-item>
            <div v-if="notifyConfig.type == 0 || notifyConfig.type == 2">
              <el-form-item label="联系人">
                <v-input
                  v-model="notifyConfig.smsConfig.contacts"
                  placeholder="请输入联系人姓名"
                />
              </el-form-item>
              <el-form-item label="联系电话">
                <v-input
                  v-model="notifyConfig.smsConfig.phone"
                  placeholder="请输入联系电话"
                />
              </el-form-item>
              <el-form-item label="短信内容模板">
                <v-input
                  v-bind="{ width: 500 }"
                  v-model="notifyConfig.smsConfig.template"
                  placeholder="请输入短信内容模板"
                />
              </el-form-item>
              <div :class="['message', { mg20: notifyConfig.type == 2 }]">
                例 尊敬的用户您的设备产生报警内容如下$content<br />$content
                指代告警内容的信息
              </div>
            </div>
            <div v-if="notifyConfig.type == 1 || notifyConfig.type == 2">
              <el-form-item label="工单类型">
                <v-select
                  v-model="notifyConfig.orderConfig.workTypeName"
                  clearable
                  :options="workOrderTypeList"
                  placeholder="请选择工单类型"
                  @change="
                    workOrderTypeChange(notifyConfig.orderConfig.workTypeName)
                  "
                />
              </el-form-item>
              <el-form-item label="事件类型">
                <v-select
                  v-model="notifyConfig.orderConfig.incidentName"
                  clearable
                  :options="eventTypeList"
                  placeholder="请选择事件类型"
                />
              </el-form-item>
              <el-form-item label="发布人">
                <v-select
                  v-model="notifyConfig.orderConfig.userId"
                  clearable
                  :options="publisherList"
                  placeholder="请选择发布人"
                />
              </el-form-item>
              <el-form-item label="工单内容模板">
                <v-input
                  v-bind="{ width: 500 }"
                  v-model="notifyConfig.orderConfig.template"
                  placeholder="请输入短信工单内容"
                />
              </el-form-item>
              <div class="message">
                例 尊敬的用户您的设备产生报警内容如下$content<br />$content
                指代告警内容的信息
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #saveBeforeSlot>
        <v-button text="下一步" v-if="active == 0" @click="eventNextStep()">
        </v-button>
        <v-button text="取消" v-if="active == 0" @click="eventCancel()">
        </v-button>
        <v-button text="上一步" v-if="active == 1" @click="backStep()">
        </v-button>
        <v-button text="下一步" v-if="active == 1" @click="nextStep()">
        </v-button>
        <v-button text="上一步" v-if="active == 2" @click="previousStep()">
        </v-button>
        <v-button text="完成" v-if="active == 2" @click="complete()">
        </v-button>
      </template>
    </form-panel>
  </div>
</template>

<script>
import {
  addRuleUrl,
  addWarningUrl,
  productListUrl,
  productAttrListUrl,
  proAllListUrl,
  workOrderTypeListUrl,
  ruleDetailUrl,
  warningDetailUrl,
  getIndependentPublisherUrl,
  getEventContentList,
  submitEventConfig,
} from "./api.js";
import { Message, MessageBox } from "element-ui";
import {
  ruleType,
  checkMode,
  notifyType,
  notifyMode,
  ruleRelationship,
  eventType,
  configRuleOPt,
} from "./map.js";
export default {
  name: "iotRuleForm",
  data() {
    return {
      //事件配置
      eventType, // 事件配置单选列表
      eventConfig: {
        id: null,
        ruleType: 1,
        issueName: "",
        spaceId: "", //园区ID
        productIds: "", // 产品ID
        spaceName: "",
        issueConfig: {
          issue_code: null,
          issue_name: null,
          issue_recover_time: null,
        },
      },
      // 规则配置
      iotRule: {
        id: null, //规则id
        spaceId: null, //园区id
        spaceName: null, //园区名称
        ruleName: null, //规则名称
        ruleType: 0, //规则类型
        rule_type: 1, //配置规则
        ruleComplexConfig: {
          //组合规则
          checkType: null, //校验方式
          configs: [
            {
              configs: [
                {
                  operates: null, //校验规则
                  value1: null,
                  value2: null,
                  value3: null,
                },
              ],
              productId: null, //产品id
              sort: null, //规则顺序，
              validTime: null, //规则有效期
            },
          ],
          ruleRelated: null, //规则链关系
          ruleRelatedLeftProducts: [],
          ruleRelatedOper: null,
          ruleRelatedRightProducts: [],
        },
        ruleSimpleConfig: {
          //单一规则
          configs: [
            {
              operates: null, //校验规则
              value1: null,
              value2: null,
              value3: null,
            },
          ],
          productId: null, //产品id
          sort: null, //规则顺序，
          validTime: null, //规则有效期
        },
      },
      // 告警配置
      notifyConfig: {
        ruleId: null, //规则id
        eventType: null, //告警类型
        eventName: null, //告警名称
        eventContent: null, //告警内容
        type: null, //通知方式，默认短信0
        smsConfig: {
          //短信通知方式配置
          contacts: null, //联系人
          phone: null, //联系电话
          template: null, //短信内容模板
        },
        orderConfig: {
          //工单通知方式配置
          workTypeName: null, //工单类型
          incidentName: null, //事件类型
          userId: null,
          template: null, //工单内容模板
        },
      },
      active: 0,
      stepData: [
        {
          title: "事件配置",
          key: 1,
        },
        {
          title: "规则配置",
          key: 2,
        },
        {
          title: "告警配置",
          key: 3,
        },
      ],
      submitConfig: {
        queryUrl: "",
        submitUrl: "",
      },
      ruleType,
      checkMode,
      notifyType,
      notifyMode,
      ruleRelationship,
      productList: [], //产品列表
      productOptionList: [], //产品属性列表
      markSelect: [],
      workOrderTypeList: [], //工单类型列表
      eventTypeList: [], //事件类型列表
      publisherList: [],
      labelWidht: "180px",
      configRuleOPt, //配置规则单选列表
      eventContentLIst: [], //事件内容数据
    };
  },
  created() {
    this.getProductList();
    this.getWorkOrderTypeList();
    this.getPublisherList();
    this.getEventContentLIst();
  },
  mounted() {
    this.getProAllListUrl("operator");

    const { id } = this.$route.query;
    if (id !== undefined) {
      this.getRuleDetail(id);
      this.iotRule.id = id;
      this.eventConfig.id = id;
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    // 事件配置下一步
    eventNextStep(id) {
      let params;
      //编辑
      if (this.eventConfig.id) {
        if (this.eventConfig.ruleType == 0) {
          params = {
            id: this.iotRule.id,
            productIds: this.eventConfig.productIds,
            spaceId: this.eventConfig.spaceId,
            spaceName: this.eventConfig.spaceName,
            issueConfig: JSON.stringify(this.eventConfig.issueConfig),
          };
        } else {
          params = {
            id: this.iotRule.id,
          };
        }
      } else {
        // 新增
        if (this.eventConfig.ruleType == 0) {
          params = {
            id: this.iotRule.id,
            productIds: this.eventConfig.productIds,
            spaceId: this.eventConfig.spaceId,
            spaceName: this.eventConfig.spaceName,
            ruleType: 2,
            issueConfig: JSON.stringify(this.eventConfig.issueConfig),
          };
        } else {
          params = {
            id: this.iotRule.id,
            ruleType: 2,
          };
        }
      }
      this.$axios.post(`${submitEventConfig}`, params).then((res) => {
        if (res.code == 200) {
          this.active = 1;
          Message.success("操作成功！");
          this.getRuleDetail(res.data);
        }
      });
    },
    // 选择事件绑定下拉事件
    handelEventBinding(value) {
      const result = this.eventContentLIst.filter(
        (item) => item.value === value
      );
      this.eventConfig.issueConfig.issue_code = result[0].value;
      this.eventConfig.issueConfig.issue_name = result[0].label;
    },
    // 获取事件内容数据
    getEventContentLIst() {
      this.$axios.get(`${getEventContentList}`).then((res) => {
        if (res.code == 200) {
          res.data[0].children.forEach((ele) => {
            let obj = {};
            obj.label = ele.dictValue;
            obj.value = ele.dictKey;
            this.eventContentLIst.push(obj);
          });
        }
      });
    },
    // 获取发布人列表数据
    async getPublisherList() {
      let params = {
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      let res = await this.$axios.get(`${getIndependentPublisherUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        res.data.forEach((item) => {
          item.value = item.userId;
          item.label = item.userName;
        });
        this.publisherList = res.data;
      }
    },
    // 取消
    eventCancel() {
      this.$router.go(-1);
    },
    eventCommunityChange(value, list) {
      list.forEach((item) => {
        if (item.id == value) {
          this.eventConfig.spaceName = item.name;
        }
      });
    },
    communityChange(value, list) {
      list.forEach((item) => {
        if (item.id == value) {
          this.iotRule.spaceName = item.name;
        }
      });
    },
    // 校验规则
    productOptionChange(item) {
      if (item == undefined) {
        this.iotRule.ruleSimpleConfig.configs[0].operates =
          (this.iotRule.ruleSimpleConfig.configs[0].value1
            ? this.iotRule.ruleSimpleConfig.configs[0].value1
            : "") +
          (this.iotRule.ruleSimpleConfig.configs[0].value2
            ? this.iotRule.ruleSimpleConfig.configs[0].value2
            : "") +
          (this.iotRule.ruleSimpleConfig.configs[0].value3
            ? this.iotRule.ruleSimpleConfig.configs[0].value3
            : "");
      } else {
        item.configs[0].operates =
          (item.configs[0].value1 ? item.configs[0].value1 : "") +
          (item.configs[0].value2 ? item.configs[0].value2 : "") +
          (item.configs[0].value3 ? item.configs[0].value3 : "");
      }
      this.$forceUpdate();
    },
    markSelectChange(item) {
      if (item == undefined) {
        this.iotRule.ruleSimpleConfig.configs[0].operates =
          (this.iotRule.ruleSimpleConfig.configs[0].value1
            ? this.iotRule.ruleSimpleConfig.configs[0].value1
            : "") +
          (this.iotRule.ruleSimpleConfig.configs[0].value2
            ? this.iotRule.ruleSimpleConfig.configs[0].value2
            : "") +
          (this.iotRule.ruleSimpleConfig.configs[0].value3
            ? this.iotRule.ruleSimpleConfig.configs[0].value3
            : "");
      } else {
        item.configs[0].operates =
          (item.configs[0].value1 ? item.configs[0].value1 : "") +
          (item.configs[0].value2 ? item.configs[0].value2 : "") +
          (item.configs[0].value3 ? item.configs[0].value3 : "");
      }
      this.$forceUpdate();
    },
    selectInput(item) {
      if (item == undefined) {
        this.iotRule.ruleSimpleConfig.configs[0].operates =
          (this.iotRule.ruleSimpleConfig.configs[0].value1
            ? this.iotRule.ruleSimpleConfig.configs[0].value1
            : "") +
          (this.iotRule.ruleSimpleConfig.configs[0].value2
            ? this.iotRule.ruleSimpleConfig.configs[0].value2
            : "") +
          (this.iotRule.ruleSimpleConfig.configs[0].value3
            ? this.iotRule.ruleSimpleConfig.configs[0].value3
            : "");
      } else {
        item.configs[0].operates =
          (item.configs[0].value1 ? item.configs[0].value1 : "") +
          (item.configs[0].value2 ? item.configs[0].value2 : "") +
          (item.configs[0].value3 ? item.configs[0].value3 : "");
      }
      this.$forceUpdate();
    },
    // 单一规则-选择产品change
    productChange(value) {
      this.getProductOptionList(value);
    },
    // 获取规则配置详情
    getRuleDetail(id) {
      this.$axios.get(`${ruleDetailUrl({ ruleId: id })}`).then((res) => {
        if (res.code == 200) {
          this.iotRule.id = res.data.id; //规则id
          this.iotRule.spaceId = res.data.spaceId; //园区id
          this.iotRule.spaceName = res.data.spaceName; //园区名称
          this.iotRule.ruleName = res.data.ruleName; //规则名称
          this.iotRule.ruleType = res.data.ruleType; //规则类型
          //****************************
          this.eventConfig.spaceName = res.data.spaceName; // 事件配置园区
          this.eventConfig.spaceId = res.data.spaceId; //事件配置园区ID
          this.eventConfig.productIds = res.data.productIds; // 事件配置产品ID
          if (res.data.issueConfig) {
            const { issue_code, issue_recover_time, issue_name } = JSON.parse(
              res.data.issueConfig
            );
            this.eventConfig.issueName = issue_code;
            this.eventConfig.issueConfig.issue_recover_time =
              issue_recover_time; //事件告警自动恢复期限
            this.eventConfig.issueConfig.issue_code = issue_code; //事件绑定issue_code
            this.eventConfig.issueConfig.issue_name = issue_name; //事件绑定issue_name
          }
          if (this.eventConfig.id) {
            res.data.hasIssue
              ? (this.eventConfig.ruleType = 0)
              : (this.eventConfig.ruleType = 1); // 配置事件单选状态
          }
          res.data.hasRule
            ? (this.iotRule.rule_type = 0)
            : (this.iotRule.rule_type = 1); //配置规则单选状态
          // ************************************
          if (res.data.ruleType == 0) {
            this.iotRule.ruleSimpleConfig.productId =
              res.data.ruleSimpleConfig.productId;
            this.iotRule.ruleSimpleConfig.validTime =
              res.data.ruleSimpleConfig.validTime;
            this.iotRule.ruleSimpleConfig.configs[0].operates =
              res.data.ruleSimpleConfig.configs[0].operates;
          } else {
            this.iotRule.ruleComplexConfig.checkType =
              res.data.ruleComplexConfig.checkType;
            this.iotRule.ruleComplexConfig.configs =
              res.data.ruleComplexConfig.configs;
            this.iotRule.ruleComplexConfig.ruleRelated =
              res.data.ruleComplexConfig.ruleRelated;
            this.iotRule.ruleComplexConfig.ruleRelatedLeftProducts =
              res.data.ruleRelatedLeftProducts;
            this.iotRule.ruleComplexConfig.ruleRelatedOper =
              res.data.ruleRelatedOper;
            this.iotRule.ruleComplexConfig.ruleRelatedRightProducts =
              res.data.ruleRelatedRightProducts;
            console.log(this.iotRule.ruleComplexConfig.ruleRelated);
          }
        }
      });
    },
    // 获取告警配置详情
    getNotifyDetail(id) {
      this.$axios.get(`${warningDetailUrl({ ruleId: id })}`).then((res) => {
        if (res.code == 200 && res.msg == "暂无承载数据") {
        } else {
          let obj = JSON.parse(res.data);
          this.notifyConfig.ruleId = obj.id;
          this.notifyConfig.eventType = obj.eventType;
          this.notifyConfig.eventName = obj.eventName;
          this.notifyConfig.eventContent = obj.eventContent;
          this.notifyConfig.type = Number(obj.type);
          if (this.notifyConfig.type == 0) {
            this.notifyConfig.smsConfig = JSON.parse(obj.smsConfig);
          } else if (this.notifyConfig.type == 1) {
            this.notifyConfig.orderConfig = JSON.parse(obj.orderConfig);
            this.workOrderTypeChange(
              this.notifyConfig.orderConfig.workTypeName
            );
          } else {
            this.notifyConfig.smsConfig = JSON.parse(obj.smsConfig);
            this.notifyConfig.orderConfig = JSON.parse(obj.orderConfig);
            this.workOrderTypeChange(
              this.notifyConfig.orderConfig.workTypeName
            );
          }
        }
      });
    },
    // 上一步按钮
    previousStep() {
      this.active = 1;
      this.getRuleDetail(this.iotRule.id);
    },
    // 下一步按钮
    nextStep() {
      if (this.iotRule.ruleComplexConfig.checkType == "1") {
        this.iotRule.ruleComplexConfig.configs.forEach((item) => {
          item.sort = null;
        });
        let ids1 =
          this.iotRule.ruleComplexConfig.ruleRelatedLeftProducts.length > 1
            ? `(${this.iotRule.ruleComplexConfig.ruleRelatedLeftProducts.join(
                "_and_"
              )})`
            : String(this.iotRule.ruleComplexConfig.ruleRelatedLeftProducts[0]);
        let ids3 =
          this.iotRule.ruleComplexConfig.ruleRelatedRightProducts.length > 1
            ? `(${this.iotRule.ruleComplexConfig.ruleRelatedRightProducts.join(
                "_and_"
              )})`
            : String(
                this.iotRule.ruleComplexConfig.ruleRelatedRightProducts[0]
              );
        this.iotRule.ruleComplexConfig.ruleRelated =
          ids1 + this.iotRule.ruleComplexConfig.ruleRelatedOper + ids3;
      }
      let params;
      if (this.iotRule.rule_type == 1) {
        params = {
          id: this.iotRule.id,
          ruleType: 2,
        };
      } else {
        if (this.iotRule.ruleType == 0) {
          params = {
            id: this.iotRule.id,
            spaceId: this.iotRule.spaceId,
            spaceName: this.iotRule.spaceName,
            ruleName: this.iotRule.ruleName,
            ruleType: this.iotRule.ruleType,
            ruleConfig: JSON.stringify(this.iotRule.ruleSimpleConfig),
          };
        } else {
          params = {
            id: this.iotRule.id,
            spaceId: this.iotRule.spaceId,
            spaceName: this.iotRule.spaceName,
            ruleName: this.iotRule.ruleName,
            ruleType: this.iotRule.ruleType,
            ruleConfig: JSON.stringify(this.iotRule.ruleComplexConfig),
          };
        }
      }
      this.$axios
        .post(`${addRuleUrl}`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.iotRule.id = res.data;
            this.notifyConfig.ruleId = res.data;
            this.getNotifyDetail(res.data);
            Message.success("操作成功！");
            this.active = 2;
          }
        });
    },
    // 完成按钮
    complete() {
      let obj = {};
      let params = {
        id: this.notifyConfig.ruleId,
        eventType: this.notifyConfig.eventType,
        eventName: this.notifyConfig.eventName,
        eventContent: this.notifyConfig.eventContent,
        type: this.notifyConfig.type,
        smsConfig:
          this.notifyConfig.type == 0 || this.notifyConfig.type == 2
            ? JSON.stringify(this.notifyConfig.smsConfig)
            : JSON.stringify({}),
        orderConfig:
          this.notifyConfig.type == 1 || this.notifyConfig.type == 2
            ? JSON.stringify(this.notifyConfig.orderConfig)
            : JSON.stringify({}),
      };
      this.$axios
        .post(`/gateway/hc-device/iot/rule/warning/submit?`, params, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            Message.success("操作成功！");
            this.$router.go(-1);
          }
        });
    },
    // 规则配置上一步
    backStep() {
      this.active = 0;
      this.getRuleDetail(this.iotRule.id);
    },
    // 删除按钮
    removeItem(item, index) {
      this.iotRule.ruleComplexConfig.configs.splice(index, 1);
    },
    // 新增按钮
    addItem(item, index) {
      let obj = {
        configs: [
          {
            operates: null, //校验规则
            value1: null,
            value2: null,
            value3: null,
          },
        ],
        productId: null, //产品id
        sort: null, //规则顺序，
        validTime: null, //规则有效期
      };
      this.iotRule.ruleComplexConfig.configs.push(obj);
    },
    // 获取产品属性列表
    getProductOptionList(id) {
      let params = {
        curPage: 1,
        pageSize: 500,
        productId: id,
      };
      this.$axios
        .get(`${productAttrListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              item.value = item.identifier;
              item.label = item.name;
            });
            this.productOptionList = res.data;
          }
        });
    },
    // 获取工单类型/事件类型列表
    getWorkOrderTypeList() {
      let params = {
        curPage: 1,
        pageSize: 500,
      };
      this.$axios
        .get(`${workOrderTypeListUrl}`, { params: params })
        .then((res) => {
          if (res.code == 200) {
            res.data.records.forEach((item) => {
              item.value = item.name;
              item.label = item.name;
            });
            this.workOrderTypeList = res.data.records;
          }
        });
    },
    // 工单类型change
    workOrderTypeChange(name) {
      console.log(name);
      console.log(123);
      let list = [];
      this.workOrderTypeList.forEach((item) => {
        if (item.name == name) {
          list = item.list;
        }
      });
      list.forEach((item) => {
        item.value = item.incidentName;
        item.label = item.incidentName;
      });
      this.eventTypeList = list;
    },
    // 获取产品列表
    getProductList() {
      let params = {
        curPage: 1,
        pageSize: 500,
      };
      this.$axios
        .get(`${productListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code == 200) {
            res.data.records.forEach((item) => {
              item.value = item.id;
              item.label = item.name;
            });
            this.productList = res.data.records;
          }
        });
    },
    async getProAllListUrl(type) {
      let params = {
        code: type,
      };
      this.kindList = [];
      let res = await this.$axios.get(`${proAllListUrl}`, {
        params,
      });
      if (res.code === 200) {
        if (res.data && res.data[0].children) {
          if (type == "operator") {
            res.data[0].children.forEach((ele) => {
              let obj = {};
              obj.label = ele.dictValue;
              obj.value = ele.dictKey;
              this.markSelect.push(obj);
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.iotProductForm {
  box-sizing: border-box;
  height: 100%;
  .message {
    color: #f00;
    font-size: 14px;
    padding-left: 140px;
    box-sizing: border-box;
  }
  .mg20 {
    margin-bottom: 20px;
  }

  .ruleRelationshipBox {
    display: flex;
    align-items: center;

    .item:nth-child(1) {
      width: 128px;
      font-size: 14px;
      color: #606266;
      padding-left: 55px;
      box-sizing: border-box;
    }

    .item:nth-child(n) {
      margin-right: 10px;
    }

    .item:nth-last-child(1) {
      color: #f00;
      font-size: 14px;
    }
  }

  .ruleBox {
    width: 100%;
    margin-bottom: 20px;

    .box {
      margin-top: 15px;
    }

    .ruleBox-bottom {
      display: flex;
      align-items: center;
      .item-lable {
        padding-left: 20px;
        box-sizing: border-box;
        width: 180px;
        font-size: 14px;
        color: #606266;
      }

      .item:nth-child(1) {
        width: 128px;
        font-size: 14px;
        color: #606266;
        padding-left: 55px;
        box-sizing: border-box;
        margin: 0;
      }

      .item:nth-child(n) {
        font-size: 14px;
        color: #606266;
        margin-right: 10px;
      }
    }

    .ruleBox-top {
      display: flex;

      .ruleBox-top-l {
        flex: 2;
        display: flex;
      }

      .ruleBox-top-r {
        flex: 1;

        .removeBtn {
          margin-right: 20px;
        }
      }
    }
  }

  .iotDeviceForm-body {
    .iotDeviceForm-b {
      padding: 40px;
    }

    .title {
      padding: 10px 40px;
    }

    .label {
      margin: 0 10px;
    }
  }

  .areaTimes {
    display: flex;
    align-items: flex-end;

    padding: 20px 0;
    border-bottom: 1px solid #eee;

    .areaTimes-l {
      margin-right: 20px;
    }

    .areaTimes-r {
      height: 46px;

      /deep/ .v-button {
        margin-right: 10px;
      }
    }

    .item {
      display: flex;
      align-content: center;
      margin: 10px 0;

      .item-l {
        display: flex;
        align-items: center;
        margin-right: 10px;
      }

      .item-r {
        display: flex;
        align-items: center;

        .range {
          margin: 0 20px;
        }
      }
    }
  }
}
</style>
